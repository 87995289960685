import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import { Accordion } from "../../core/Accordion"
import { Section, SectionWrapper, SubTitle } from "../../core/commonExports"

const FAQs = ({adLP = true}) => {
  return (
    <Section background="#fff">
      <SectionWrapper> 
          <SubTitle fontSize="42px" mfontSize="20px" lineHeight="62px" mlineHeight="36px" fontWeight="bold" color="#333333" style={{textAlign: "left", margin: "0", marginBottom: '40px'}}>
          Frequently Asked Questions
          </SubTitle>
          <Accordion
            title="Q1: As per IRDAI, is it mandatory to take a safety plan?"
            content="No, it is not compulsory to have a CCTV camera or Fire Extinguisher. But, it is beneficial for your business to be completely secure - this reduces your insurance premium and reduces the time taken to process an insurance claim."
          />
          <Accordion
            title="Q2: Which insurance company provides the insurance?"
            content="The insurance provider in your safety plan would be either United Insurance, Oriental Insurance or National Insurance. You will be issued a premium confirmation receipt for your own records."
          />
          <Accordion
            title="Q3.What is the approximate cost which I would need to pay?"
            content={
              <div style={{display: "flex", flexDirection: "column"}}>
                The amount you have to pay would depend on your coverage.
                <br /> <br />
                <StyledTable>
                  <tr>
                    <th>Coverage Amount (₹)</th>
                    <th>Estimated Cost (₹) (inclusive of GST)</th>
                  </tr>
                  <tr>
                    <td>1-7 Lacs</td>
                    <td>3000</td>
                  </tr>
                  <tr>
                    <td>8-12 Lacs</td>
                    <td>3500</td>
                  </tr>
                  <tr>
                    <td>13-14 Lacs</td>
                    <td>4000</td>
                  </tr>
                  <tr>
                    <td>15-19 Lacs</td>
                    <td>5000</td>
                  </tr>
                  <tr>
                    <td>20-24 Lacs</td>
                    <td>8000</td>
                  </tr>
                  <tr>
                    <td>25-39 Lacs</td>
                    <td>10000</td>
                  </tr>
                  <tr>
                    <td>40 Lac and above</td>
                    <td>Contact Sales</td>
                  </tr>
                </StyledTable>
                <br />
                Actual prices vary based on your business location, type of facility & related risk factors.
              </div>
            }
          />
          <Accordion
            title="Q4: Can I apply for the camera after a few days by taking only the policy?"
            content={
              <>
                No, this is not possible because this is a service package. Moreover, certain insurers (e.g. National Insurance) will NOT provide Burglary & Theft insurance if you don’t have a CCTV camera installed. <br />
                After you purchase the package, we will issue the insurance policy bond only after you send us photos of hardware installation at your premise. The reason is that your policy will state that you have CCTV installed, and this will reduce your premium.
              </>
            }
          />
          <Accordion
            title="Q5: What CCTV camera does Verak offer?"
            content={<>
              Verak offers a Zebion 2.0 MP smart wifi camera. This comes with a built-in alarm and cloud storage & backup as well as a 32 GB memory card.
              <br /><br />
              <StaticImage 
                src="../../../assets/images/safety-camera-sm.png"
              />
              <div style={{fontSize: "12px"}}>
                Model - Argoz Grand Plus 200 Security Camera
                <ul>
                  <li>Cloud Storage & backup</li>
                  <li>Linked to Mobile App - View Camera Footage and Sound Alarm</li>
                  <li>1080p footage</li>
                  <li>Built in alarm system</li>
                  <li>Dimensions - 20 x 14 x 14 centimetres</li>
                  <li>Newly upgraded product : July 22 release</li>
                </ul>
              </div>
            </>}
          />
          <Accordion
            title="Q6: Is there any warranty or guarantee available for Camera?"
            content="Yes, a one-year warranty is available. The warranty covers product-related or technical issues."
          />
          <Accordion
            title="Q7: How long does the delivery of the CCTV camera take?"
            content="7 to 8 working days"
          />
          {/* {!adLP && (<Accordion
            title="Q8: What Fire Extinguisher does Verak offer?"
            content="Right now, our safety plans do NOT include Fire Extinguishers. We will be offering this from February 2023 onwards."
          />)} */}
      </SectionWrapper>
    </Section>
  )
}

const StyledTable = styled.table`
  max-width: 600px;
  border: 1px solid #EEEAF5;
  border-collapse: collapse;
  th {
    color: #fff;
    background: #2A2B7F;
    border-bottom: 1px solid #FFFFFF;
    text-align: left;
  }
  tr {
    background: #fff;
    border-bottom: 1px solid #EEEAF5;
  }
  td, th {
    padding: 12px;
  }

  th:first-of-type {
    border-top-left-radius: 8px;
  }
  th:last-of-type {
    border-top-right-radius: 8px;
  }
  tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 8px;
  }
  tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 8px;
  }
`

export default FAQs